import React, { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./fonts.css";
import "./App.css";
import Footer from "./components/Footer"
import Navbar from "./components/Navbar"
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import i18n from 'i18next';

interface AppProps {
  children: ReactNode;
}

const App: React.FC<AppProps> = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [state, setState] = useState(null);
  const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');

  const changeLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'ru' : 'en';
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem('i18nextLng', newLanguage);
  };

  const callBackendAPI = async () => {
    const response = await fetch('/express_server');
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message)
    }
    return body;
  };

  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => { });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
    };
  }, []);

  // Defining functions to perform different types of scrolling.
  const scrollToTop = (speed: number) => {
    scroll.scrollToTop({
      duration: speed,
    });
  };

  // получение GET маршрута с сервера Express, который соответствует GET из server.js 
  useEffect(() => {
    callBackendAPI()
      .then(res => setState(res.express))
      .catch(err => console.log(err));
  }, [])

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      if (currentScrollTop > 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }

      if (currentScrollTop < lastScrollTop) {
        setIsScrolled(false);
      }

      setLastScrollTop(currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };





  }, [lastScrollTop]);




  const headerClasses = `header_menu ${isScrolled ? "scrolled" : "scrolled-up"}`;




  return (
    <>
      <div className="App">
        <Navbar />
      </div>
      {children}
      <Footer />
    </>
  );
};

export default App;
