import "./Footer.css";
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';

const Footer = () => {
  useEffect(() => {
    // Registering the 'begin' event and logging it to the console when triggered.
    Events.scrollEvent.register('begin', (to, element) => { });

    // Updating scrollSpy when the component mounts.
    scrollSpy.update();

    // Returning a cleanup function to remove the registered events when the component unmounts.
    return () => {
      Events.scrollEvent.remove('begin');
    };
  }, []);

  // Defining functions to perform different types of scrolling.
  const scrollToTop = (speed: number) => {
    scroll.scrollToTop({
      duration: speed,
    });
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer_block-content">
        <Link onClick={() => scrollToTop(100)} className="footer_block-content_element" to='/'>About</Link>
        <Link onClick={() => scrollToTop(100)} className="footer_block-content_element" to="/articles">Articles</Link>
      </div>
      <hr className="hr_foter"></hr>
      <div className="links">
        <a className="links2" href="https://www.kaggle.com/gennadiimanzhos">
          <img src="https://cdn.icon-icons.com/icons2/2389/PNG/512/kaggle_logo_icon_145140.png" alt="" />
        </a>
        <a className="links1" href="https://github.com/everythingisc00l">
          <img src="https://cdn-icons-png.flaticon.com/512/25/25231.png" alt="" />
        </a>
        <a className="links3" href="https://www.linkedin.com/in/gennadii-manzhos-869911200/">
          <img src="https://cdn.icon-icons.com/icons2/2428/PNG/512/linkedin_black_logo_icon_147114.png" alt="" />
        </a>
      </div>
      <div className="footer_block-content_copyrights">© {currentYear} Manzhos Gennadiy | Data Scientist & ML Engineer</div>
    </div>
  );
};

export default Footer;