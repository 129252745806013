import { Link } from "react-router-dom";
import React, { ReactNode, useState, useEffect } from 'react';
import "./Articles.css";
import i18n from 'i18next';
import { useTranslation } from "react-i18next";
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

interface AnimatedBlockProps {
  children: ReactNode;
  delay?: number;
}

const AnimatedBlock: React.FC<AnimatedBlockProps> = ({ children, delay = 0 }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <motion.div

      ref={ref}
      initial={{ opacity: 0, y: 20 }}
      animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
      transition={{ duration: 0.5, delay }}
    >
      {children}
    </motion.div>
  );
};




const Articles = () => {

  const { t } = useTranslation();
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  };

  return (<div className="home_page_block">
    <div className="home_page_block-info">
      <div id="about" className="home_page_title">Articles</div>
      <div className="home_page_title_2">Conferences, articles, publications</div>
      <AnimatedBlock>
        <img className="photo_article" src="photo_article.jpg"
          alt="photo_article" />
      </AnimatedBlock>
      <div className="articles_page_title_job">
        Digital department marathon, 2023, Nizhniy Novgorod.
      </div>
      <br />
      <hr className="line_break2" />
      <div className="home_page_category_content">
        <AnimatedBlock delay={0.4}>
          <div className="home_page_category_title"> {t('Conferences')}</div>
          <div className="articles_page_category_info">
            <Link className="articles_page_title_name" to="https://www.it52.info/events/2023-12-08-pythonn-mitap-pitonistov-08-12">
              PythoNN: Meetup 08.12.2023
            </Link>
            <div className="articles_page_title_job">
              MTS StartUp Hub, {t('Nizhniy Novgorod')}
            </div>
            <br />
            <br />
            <hr className="line_break2" />
            <br />
          </div>
        </AnimatedBlock>
        <AnimatedBlock delay={0.5}>
          <div className="home_page_category_title">{t('Publications')}</div>
          <div>
            <Link className="articles_page_title_name" to="https://elibrary.ru/item.asp?id=60025789">
              {t('Artificial Intelligence for Medicine Practice')}
            </Link>
            <div className="articles_page_title_job">
              {t('DIGITAL TRANSFORMATION OF EDUCATION: CURRENT STATE')}
              <br />
              {t('Collection of scientific papers based on materials of International Scientific and Practical conferences')}
              <br />
              {t('Publisher')}: {t('Kursk State Medical University')}, 2024.
            </div>
          </div>

          <br />
          <div>
            <Link className="articles_page_title_name" to="https://elibrary.ru/item.asp?id=50249327">
              {t('Meta-competencies in digital transformation in Medical University')}
            </Link>
            <div className="articles_page_title_job">
              {t('INNOVATIVE TECHNOLOGIES IN EDUCATIONAL ACTIVITIES')}
              <br />{t('Materials of the XXV International Scientific and Methodological Conference')}
              <br />
              {t('Publisher')}: {t('Nizhny Novgorod State Technical University')}, 2023.
            </div>
          </div>
          <br />
          <div>
            <Link className="articles_page_title_name" to="https://elibrary.ru/item.asp?id=54481768">
              {t('Educational and methodological manual: Python programming language for medical students')}
            </Link>
            <div className="articles_page_title_job">
              {t('Publisher')}: {t('Privolzhsky Research Medical University')}, 2023.
            </div>
          </div>
          <br />
          <div>
            <Link className="articles_page_title_name" to="https://elibrary.ru/item.asp?id=54481768">
              {t('Educational and methodological manual: Python programming language for medical students. Second part')}
            </Link>
            <div className="articles_page_title_job">
              {t('Publisher')}: {t('Privolzhsky Research Medical University')}, 2024.
            </div>
          </div>
          <br />
          <div>
            <Link className="articles_page_title_name" to="https://www.elibrary.ru/item.asp?id=50240140">
              {t('Using modern development tools when studying at Medical University')}
            </Link>
            <div className="articles_page_title_job">
              {t('CURRENT ISSUES IN HEALTHCARE ORGANIZATION')}
              <br />
              {t('Collection of scientific papers of the VI All-Russian Scientific and Practical Conference. Volume Issue XVI')}
              <br />
              {t('Publisher')}: {t('Privolzhsky Research Medical University')}, 2023.
            </div>
          </div>
          <br />
          <div>
            <Link className="articles_page_title_name" to="https://www.elibrary.ru/item.asp?id=50176144">
              {t('Jupyter Notebook development environment as a tool for medical students')}
            </Link>
            <div className="articles_page_title_job">
              {t('DIGITAL TRANSFORMATION OF EDUCATION: CURRENT STATE')}
              <br />
              {t('Collection of scientific papers based on materials of International Scientific and Practical conferences')}
              < br />
              {t('Publisher')}: {t('Kursk State Medical University')}, 2023.
            </div>
          </div>
        </AnimatedBlock>
        <br />
        <br />
        <br />
        <AnimatedBlock delay={0.3}>
          <a className="home_page_contact_links1" href="mailto:contact@manzhosgennadiy.ru">{t('contacts')}</a>
        </AnimatedBlock></div>

    </div>


  </div >
  );
};

export default Articles;
