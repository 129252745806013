import { Routes, Route } from "react-router-dom";

// Импорт компонентов
import App from "./App";
import Home from "./components/Home";
import Articles from "./components/Articles";



// Прописываем пути url к компонентам
const AppRoutes = () => (
  <App>
    <Routes>
      <Route path="/" element={<Home />} />
      {/* <Route path="/news" element={<News />} />
      <Route path="/news_item" element={<News_item />} /> */}
      <Route path="/articles" element={<Articles />} />
    </Routes>
  </App>
);

export default AppRoutes;
