import React, { ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Events, animateScroll as scroll, scrollSpy } from 'react-scroll';
import i18n from 'i18next';
import "./Navbar.css";


const Navbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [currentLanguage, setCurrentLanguage] = useState(localStorage.getItem('i18nextLng') || 'en');

    const changeLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'ru' : 'en';
        i18n.changeLanguage(newLanguage);
        setCurrentLanguage(newLanguage);
        localStorage.setItem('i18nextLng', newLanguage);
    };


    useEffect(() => {
        // Registering the 'begin' event and logging it to the console when triggered.
        Events.scrollEvent.register('begin', (to, element) => { });

        // Updating scrollSpy when the component mounts.
        scrollSpy.update();

        // Returning a cleanup function to remove the registered events when the component unmounts.
        return () => {
            Events.scrollEvent.remove('begin');
        };
    }, []);

    // Defining functions to perform different types of scrolling.
    const scrollToTop = (speed: number) => {
        scroll.scrollToTop({
            duration: speed,
        });
    };

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollTop = window.scrollY;

            if (currentScrollTop > 10) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }

            if (currentScrollTop < lastScrollTop) {
                setIsScrolled(false);
            }

            setLastScrollTop(currentScrollTop);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };

    }, [lastScrollTop]);

    const headerClasses = `header_menu ${isScrolled ? "scrolled" : "scrolled-up"}`;

    return (
        <>
            <div className={headerClasses}>
                <div className="header_menu">
                    <div className="header_first">
                        <Link onClick={() => scrollToTop(100)} className="header_link3" to="/">
                            Gennadiy Manzhos
                        </Link>
                        <div className="header_link2">Data Scientist</div>
                    </div>
                    <div className='switch_language_buttons'>
                        <button className='switch_language_button' onClick={changeLanguage}>
                            {currentLanguage === 'en' ? (
                                <>EN
                                </>
                            ) : (
                                <>RU
                                </>
                            )}
                        </button>
                    </div>
                    <Link className="header_flex1" onClick={() => scrollToTop(100)} to="/articles">
                        Articles
                    </Link>
                    <Link className="header_flex1" onClick={() => scrollToTop(100)} to="/">
                        About
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Navbar;
